import React, { useEffect, useState } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import Social from '../components/social'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from '../utils/api'

function Main() {
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_URL}/api/category`).then((res) => {
            setCategories(res.data);
        }).catch((e) => {
            setError(e.message);
            setCategories([]);
        })
        setIsLoading(false);
    }, [])

    return (
        <div className="container">
            <Header />
            <Social />
            <div className="welcome-text">Dobro Došli</div>
            <div className="category-selector">
                {
                    isLoading ? (
                        <>
                            <div className='category-loading'>Loading ...</div>
                            <div className='category-loading'>Loading ...</div>
                            <div className='category-loading'>Loading ...</div>
                            <div className='category-loading'>Loading ...</div>
                        </>
                    ) : categories.map((category) => {
                        return (
                            <Link key={category.id} to={`category/${category.id}`}>
                                <div className="category"
                                    style={{ background: `rgb(0, 0, 0, 0.31) url(${API_URL}${category.image}) no-repeat local center / cover` }}>
                                    <span>{category.name}</span>
                                </div>
                            </Link>
                        )
                    })
                }
                {
                    error && (<div>{error}</div>)
                }
            </div>
            <Footer />
        </div>
    )
}

export default Main