import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API_URL } from '../../utils/api';
import { resizeAndConvertToWebp } from '../../utils/image';
import { Link } from 'react-router-dom';

function Categories() {
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [editData, setEditData] = useState(null);

    const [name, setName] = useState("");
    const [image, setImage] = useState(null);

    function handleNameChange(event) {
        setName(event.target.value);
    }

    function handleImageChange(event) {
        setImage(event.target.files[0]);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        if (image) {
            const img = await resizeAndConvertToWebp(image, 1000, 1000)
            formData.append('image', img);
        }

        const endpoint = editData ? `${API_URL}/api/category/${editData.id}` : `${API_URL}/api/category`;

        axios.post(endpoint, formData).then((res) => {
            console.log(res);
            setEditData(null);
            setName('');
            setImage(null);

            if (editData) {
                const newState = categories.map(obj => {
                    if (obj.id === res.data.id) {
                        return { ...obj, name: res.data.name, image: res.data.image };
                    }

                    return obj;
                });

                setCategories(newState);
            } else {
                setCategories((prevState) => {
                    return [...prevState, res.data];
                });
            }

        })
    }

    const handleCategoryDelete = (id) => {
        axios.delete(`${API_URL}/api/category/${id}`).then((res) => {
            setCategories((prevState) => {
                return prevState.filter((cat) => cat.id !== res.data.id)
            });
            // HENDLUJ DA IZBRISE IZ STATE KATEGORIJU
        })
    }

    const handleCategoryUpdate = (category) => {
        setEditData(category);
        setName(category.name);
    }

    const handleFormReset = () => {
        setEditData(null);
        setName('');
        setImage(null);
    }

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_URL}/api/category`).then((res) => {
            setCategories(res.data);
        }).catch((e) => {
            setError(e.message);
            setCategories([]);
        })
        setIsLoading(false);
    }, [])

    return (
        <div className='admin-categories'>
            <div className='admin-add-category'>
                <div className='admin-form-title'>{editData ? 'Izmeni izabranu kategoriju' : 'Dodaj kategoriju'}</div>
                <form className='admin-form' onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Naziv:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={handleNameChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="image">Slika:</label>
                        <input
                            type="file"
                            id="image"
                            accept="image/*"
                            value={""}
                            onChange={handleImageChange}
                        />
                        {image && <div>Slika izabrana</div>}
                    </div>
                    <button className='admin-submit' type="submit">{editData ? 'Izmeni' : 'Dodaj'}</button>
                    {editData && <button className='admin-reset' onClick={handleFormReset}>Otkazi</button>}
                </form>
            </div>
            <div className='admin-title'> Lista svih kategorija </div>
            <div className='category-selector'>
                {categories.map((category) => {
                    return (
                        <div key={category.id} className='admin-category'>
                            <Link to={`/admin/subcategories/${category.id}`}>
                                <div className="category"
                                    style={{ background: `rgb(0, 0, 0, 0.31) url(${API_URL}${category.image}) no-repeat local center / cover` }}>
                                    <span>{category.name}</span>
                                </div>
                            </Link>

                            <div className='admin-buttons'>
                                <button className='admin-button admin-red' onClick={() => handleCategoryDelete(category.id)}>Obrisi</button>
                                <button className='admin-button admin-green' onClick={() => handleCategoryUpdate(category)}>Izmeni</button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Categories