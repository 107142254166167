import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Main from './pages/main';
import Category from './pages/category';
import Login from './pages/admin/login';
import Categories from './pages/admin/categories';
import Subcategories from './pages/admin/subcategories';
import Items from './pages/admin/items';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/category/:id",
    element: <Category />,
  },
  {
    path: "/admin",
    children: [
      {
        path: "login",
        element: <Login />
      },
      {
        path: "categories",
        element: <Categories />
      },
      {
        path: "subcategories/:id",
        element: <Subcategories />
      },
      {
        path: "items/:id",
        element: <Items />
      }
    ]
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
