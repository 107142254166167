import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API_URL } from '../../utils/api';
import { Link, useParams } from 'react-router-dom';

function Subcategories() {
    const [subcategories, setSubcategories] = useState([]);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [editData, setEditData] = useState(null);

    const [name, setName] = useState("");

    const { id } = useParams();

    function handleNameChange(event) {
        setName(event.target.value);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        let submitPromise = null;

        if (editData) {
            submitPromise = axios.put(`${API_URL}/api/subcategory/${editData.id}`, {
                name
            })
        } else {
            submitPromise = axios.post(`${API_URL}/api/subcategory/`, {
                name,
                category_id: id
            })
        }

        submitPromise.then((res) => {
            if (editData) {
                const newState = subcategories.map(obj => {
                    if (obj.id === res.data.id) {
                        return { ...obj, name: res.data.name };
                    }

                    return obj;
                });

                setSubcategories(newState);
            } else {
                setSubcategories((prevState) => {
                    return [...prevState, res.data];
                });
            }

            setEditData(null);
            setName('');
        })
    }

    const handleSubcategoryDelete = (id) => {
        axios.delete(`${API_URL}/api/subcategory/${id}`).then((res) => {
            setSubcategories((prevState) => {
                return prevState.filter((cat) => cat.id !== res.data.id)
            });
            // HENDLUJ DA IZBRISE IZ STATE KATEGORIJU
        })
    }

    const handleSubcategoryUpdate = (subcategory) => {
        setEditData(subcategory);
        setName(subcategory.name);
    }

    const handleFormReset = () => {
        setEditData(null);
        setName('');
    }

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_URL}/api/subcategory/${id}`).then((res) => {
            setSubcategories(res.data);
        }).catch((e) => {
            setError(e.message);
            setSubcategories([]);
        })
        setIsLoading(false);
    }, [])

    return (
        <div className='admin-categories'>
            <div className='admin-add-category'>
                <div className='admin-form-title'>{editData ? 'Izmeni izabranu podkategoriju' : 'Dodaj podkategoriju'}</div>
                <form className='admin-form' onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Naziv:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={handleNameChange}
                        />
                    </div>
                    <button className='admin-submit' type="submit">{editData ? 'Izmeni' : 'Dodaj'}</button>
                    {editData && <button className='admin-reset' onClick={handleFormReset}>Otkazi</button>}
                </form>
            </div>
            <div className='admin-title'> Lista svih podkategorija za zadatu kategoriju </div>
            <div className='subcategory-selector'>
                {subcategories.map((subcategory) => {
                    return (
                        <div key={subcategory.id} className='admin-subcategory'>
                            <Link to={`/admin/items/${subcategory.id}`}>{subcategory.name}</Link>
                            <div className='admin-buttons'>
                                <button className='admin-button admin-red' onClick={() => handleSubcategoryDelete(subcategory.id)}>Obrisi</button>
                                <button className='admin-button admin-green' onClick={() => handleSubcategoryUpdate(subcategory)}>Izmeni</button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Subcategories