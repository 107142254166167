import React from 'react'
import { DecorativeLine, Logo } from '../assets/photos/photos'

function Header() {
    return (
        <header>
            <img className="line" src={DecorativeLine} alt="Left line" />
            <img className="logo" src={Logo} alt="Parma Caffe Logo" />
            <img className="line" src={DecorativeLine} alt="Right line" style={{ transform: 'rotateZ(180deg)' }} />
        </header>
    )
}

export default Header