import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API_URL } from '../../utils/api';
import { Link, useParams } from 'react-router-dom';

function Items() {
    const [items, setItems] = useState([]);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [editData, setEditData] = useState(null);

    const [name, setName] = useState("");
    const [price, setPrice] = useState(0);
    const [description, setDescription] = useState("");

    const { id } = useParams();

    function handleNameChange(event) {
        setName(event.target.value);
    }

    function handlePriceChange(event) {
        setPrice(event.target.value);
    }

    function handleDescriptionChange(event) {
        setDescription(event.target.value);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        let submitPromise = null;

        if (editData) {
            submitPromise = axios.put(`${API_URL}/api/item/${editData.id}`, {
                name,
                price,
                description
            })
        } else {
            submitPromise = axios.post(`${API_URL}/api/item/`, {
                name,
                price,
                description,
                subcategory_id: id
            })
        }

        submitPromise.then((res) => {
            if (editData) {
                const newState = items.map(obj => {
                    if (obj.id === res.data.id) {
                        return { ...obj, name: res.data.name, price: res.data.price, description: res.data.description };
                    }

                    return obj;
                });

                setItems(newState);
            } else {
                setItems((prevState) => {
                    return [...prevState, res.data];
                });
            }

            setEditData(null);
            setName('');
            setPrice(0);
            setDescription('');
        })
    }

    const handleItemDelete = (id) => {
        axios.delete(`${API_URL}/api/item/${id}`).then((res) => {
            setItems((prevState) => {
                return prevState.filter((it) => it.id !== res.data.id)
            });
            // HENDLUJ DA IZBRISE IZ STATE KATEGORIJU
        })
    }

    const handleItemUpdate = (item) => {
        setEditData(item);
        setName(item.name);
        setPrice(item.price);
        setDescription(item.description ?? '');
    }

    const handleFormReset = () => {
        setEditData(null);
        setName('');
        setPrice(0);
        setDescription('');
    }

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${API_URL}/api/item/${id}`).then((res) => {
            setItems(res.data);
        }).catch((e) => {
            setError(e.message);
            setItems([]);
        })
        setIsLoading(false);
    }, [])

    return (
        <div className='admin-categories'>
            <div className='admin-add-category'>
                <div className='admin-form-title'>{editData ? 'Izmeni izabrani proizvod' : 'Dodaj proizvod'}</div>
                <form className='admin-form' onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Naziv:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={handleNameChange}
                        /><br />
                        <label htmlFor="price">Cena:</label>
                        <input
                            type="number"
                            id="price"
                            value={price}
                            onChange={handlePriceChange}
                        /><br />
                        <label htmlFor="desc">Opis:</label>
                        <input
                            type="text"
                            id="desc"
                            value={description}
                            onChange={handleDescriptionChange}
                        />
                    </div>
                    <button className='admin-submit' type="submit">{editData ? 'Izmeni' : 'Dodaj'}</button>
                    {editData && <button className='admin-reset' onClick={handleFormReset}>Otkazi</button>}
                </form>
            </div>
            <div className='admin-title'> Lista svih proizvoda za zadatu podkategoriju </div>
            <div className='subcategory-selector'>
                {items.map((item) => {
                    return (
                        <div key={item.id} className='admin-subcategory'>
                            {item.name}<br />
                            {item.price}<br />
                            {item.description}
                            <div className='admin-buttons'>
                                <button className='admin-button admin-red' onClick={() => handleItemDelete(item.id)}>Obrisi</button>
                                <button className='admin-button admin-green' onClick={() => handleItemUpdate(item)}>Izmeni</button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Items